import {
  IdJsonSchema,
  MultiLanguageJsonSchema,
  deserializeDate,
  deserializeOptionalDate,
  serializeDate,
  serializeOptionalDate,
} from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import {
  EmspContract,
  EmspContractStatus,
  ListEmspContract,
  UnlinkedEmspContract,
} from '@model/evd/emsp';
import { z } from 'zod';

import {
  EmspContractLocationJsonSchema,
  deserializeEmspContractLocation,
  serializeEmspContractLocation,
} from './emsp-contract-location.io';
import { EmspSubscriptionPlanSchema } from './emsp-subscription-plan.io';

export const EmspContractJsonSchema = z.object({
  driverContractId: IdJsonSchema,
  organizationName: z.string(),
  contractName: MultiLanguageJsonSchema,
  contractDescription: MultiLanguageJsonSchema,
  startsOn: z.string(),
  endsOn: z.string().optional(),
  currencyCode: z.string(),
  subscriptionPlan: EmspSubscriptionPlanSchema.optional(),
  driverLocations: z.array(EmspContractLocationJsonSchema),
});

export const ListEmspContractJsonSchema = z.object({
  name: MultiLanguageJsonSchema,
  driverContractId: IdJsonSchema,
  organizationName: z.string(),
  startsOn: z.string(),
  endsOn: z.string().optional(),
  pricePerMonth: z.number().optional(),
  status: z.nativeEnum(EmspContractStatus),
  currencyCode: z.string(),
  _links: LinksJson(environment.urls.api).optional(),
});

export const UnlinkedEmspContractJsonSchema = z.object({
  driverContractId: IdJsonSchema,
  name: MultiLanguageJsonSchema,
});

export type EmspContractJson = z.infer<typeof EmspContractJsonSchema>;
export type ListEmspContractJson = z.infer<typeof ListEmspContractJsonSchema>;
export type UnlinkedEmspContractJson = z.infer<
  typeof UnlinkedEmspContractJsonSchema
>;

export function serializeEmspContract(
  contract: EmspContract,
): EmspContractJson {
  return {
    ...contract,
    startsOn: serializeDate(contract.startsOn),
    endsOn: serializeOptionalDate(contract.endsOn),
    driverContractId: contract.id,
    driverLocations: contract.locations.map(serializeEmspContractLocation),
  };
}

export function deserializeEmspContract(
  contract: EmspContractJson,
): EmspContract {
  return {
    ...contract,
    id: contract.driverContractId,
    startsOn: deserializeDate(contract.startsOn),
    endsOn: deserializeOptionalDate(contract.endsOn),
    locations: contract.driverLocations.map(deserializeEmspContractLocation),
  };
}

export function deserializeListEmspContract(
  contract: ListEmspContractJson,
): ListEmspContract {
  return {
    ...contract,
    id: contract.driverContractId,
    endsOn: deserializeOptionalDate(contract.endsOn),
    startsOn: deserializeDate(contract.startsOn),
  };
}

export function deserializeUnlinkedEmspContract(
  contract: UnlinkedEmspContractJson,
): UnlinkedEmspContract {
  return {
    ...contract,
    id: contract.driverContractId,
  };
}
