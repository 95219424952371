import {
  Address,
  Link,
  MultiLanguage,
  WithId,
  WithLinks,
  getMandatoryRelation,
} from '@model';

import { EmspSubscriptionPlan } from './emsp-subscription-plan';

export const SELF_ACCEPT_RELATION = 'self:accept';

export interface EmspOfferInvitation extends WithLinks, WithId {
  emspName?: string;
  endDate?: Date;
  isValid: boolean;
  offerName?: MultiLanguage;
  startDate?: Date;
  termsOfServiceUrl?: string;
  subscriptionPlan?: EmspSubscriptionPlan;
  driverBillingAddress?: Address;
}

export function getMandatoryAcceptLink(invitation: EmspOfferInvitation): Link {
  return getMandatoryRelation(invitation, SELF_ACCEPT_RELATION);
}
