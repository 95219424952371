import { IdJsonSchema, deserializeDate, serializeDate } from '@api';
import { EmspContractReportTransaction } from '@model/evd/emsp';
import { z } from 'zod';

export const EmspContractReportTransactionJsonSchema = z.object({
  transactionId: IdJsonSchema,
  startedOn: z.string(),
  description: z.string(),
  durationSeconds: z.number(),
  consumptionWh: z.number(),
  amount: z.number(),
  transactionCurrencyCode: z.string(),
});

export type EmspContractReportTransactionJson = z.infer<
  typeof EmspContractReportTransactionJsonSchema
>;

export function serializeEmspContractReportTransaction(
  transaction: EmspContractReportTransaction,
): EmspContractReportTransactionJson {
  return {
    ...transaction,
    transactionId: transaction.id,
    startedOn: serializeDate(transaction.startedOn),
    durationSeconds: transaction.duration,
    consumptionWh: transaction.consumption,
  };
}

export function deserializeEmspContractReportTransaction(
  transaction: EmspContractReportTransactionJson,
): EmspContractReportTransaction {
  return {
    ...transaction,
    id: transaction.transactionId,
    startedOn: deserializeDate(transaction.startedOn),
    consumption: transaction.consumptionWh / 1000,
    duration: transaction.durationSeconds * 1000,
  };
}
