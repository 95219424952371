import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, makeLink } from '@model';
import { EmspOfferInvitationAcceptance } from '@model/evd/emsp';
import { ZodType } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  EmspOfferInvitationAcceptanceJson,
  EmspOfferInvitationAcceptanceJsonSchema,
  deserializeEmspOfferInvitationAcceptance,
  serializeEmspOfferInvitationAcceptance,
} from './io/software-license-invitation-acceptance.io';

@Injectable()
export class EmspOfferInvitationAcceptanceApiService extends AbstractEvdApiService<
  EmspOfferInvitationAcceptance,
  EmspOfferInvitationAcceptanceJson
> {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(
    item: EmspOfferInvitationAcceptance,
  ): EmspOfferInvitationAcceptanceJson {
    return serializeEmspOfferInvitationAcceptance(item);
  }
  protected deserialize(
    item: EmspOfferInvitationAcceptanceJson,
  ): EmspOfferInvitationAcceptance {
    return deserializeEmspOfferInvitationAcceptance(item);
  }
  protected getLinksLink(): Link {
    return makeLink(`/license-invitations/.links`);
  }
  protected getJsonSchema(): ZodType<EmspOfferInvitationAcceptanceJson> {
    return EmspOfferInvitationAcceptanceJsonSchema;
  }
  protected getListJsonSchema(): ZodType<EmspOfferInvitationAcceptanceJson> {
    return this.getJsonSchema();
  }
  protected listDeserialize(
    item: EmspOfferInvitationAcceptanceJson,
  ): EmspOfferInvitationAcceptance {
    return this.deserialize(item);
  }
}
