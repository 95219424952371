import { z } from 'zod';

export const EmspChargingSessionFeeSchema = z.object({
  fixedPrice: z.number().optional(),
  pricePerKwh: z.number().optional(),
  pricePerMinute: z.number().optional(),
  idleFreeMinutes: z.number().optional(),
  idlePricePerMinute: z.number().optional(),
});

export type EmspChargingSessionFeeJson = z.infer<
  typeof EmspChargingSessionFeeSchema
>;
