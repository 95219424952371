import {
  IdJsonSchema,
  MultiLanguageJsonSchema,
  deserializeDate,
  serializeDate,
} from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { EmspContractReport, ListEmspContractReport } from '@model/evd/emsp';
import { z } from 'zod';

import {
  EmspContractReportTransactionJsonSchema,
  deserializeEmspContractReportTransaction,
  serializeEmspContractReportTransaction,
} from './emsp-contract-report-transaction';

export const EmspContractReportJsonSchema = z.object({
  driverContractId: IdJsonSchema,
  driverContractName: MultiLanguageJsonSchema,
  periodStartDate: z.string(),
  periodEndDate: z.string(),
  subscriptionPlanPrice: z.number(),
  totalBilledWithSubscription: z.number(),
  currencyCode: z.string(),
  fromEmsp: z.object({
    name: z.string(),
    billingAddress: z.string(),
  }),
  toEvDriver: z.object({
    firstName: z.string(),
    lastName: z.string(),
    billingAddress: z.string(),
  }),
  transactions: z.array(EmspContractReportTransactionJsonSchema),
  _links: LinksJson(environment.urls.api).optional(),
});
export type EmspContractReportJson = z.infer<
  typeof EmspContractReportJsonSchema
>;

export const ListEmspContractReportJsonSchema = z.object({
  driverContractId: IdJsonSchema,
  driverContractName: MultiLanguageJsonSchema,
  currencyCode: z.string(),
  yearMonth: z.string(),
  totalBilledWithSubscription: z.number(),
  _links: LinksJson(environment.urls.api).optional(),
});
export type ListEmspContractReportJson = z.infer<
  typeof ListEmspContractReportJsonSchema
>;

export function serializeEmspContractReport(
  report: EmspContractReport,
): EmspContractReportJson {
  return {
    ...report,
    driverContractId: report.id,
    driverContractName: report.name,
    periodStartDate: serializeDate(report.periodStartDate),
    periodEndDate: serializeDate(report.periodEndDate),
    transactions: report.transactions.map(
      serializeEmspContractReportTransaction,
    ),
  };
}

export function deserializeEmspContractReport(
  report: EmspContractReportJson,
): EmspContractReport {
  return {
    ...report,
    id: report.driverContractId,
    name: report.driverContractName,
    periodEndDate: deserializeDate(report.periodEndDate),
    periodStartDate: deserializeDate(report.periodStartDate),
    transactions: report.transactions.map(
      deserializeEmspContractReportTransaction,
    ),
  };
}

export function deserializeListEmspContractReport(
  report: ListEmspContractReportJson,
): ListEmspContractReport {
  return {
    ...report,
    id: report.driverContractId,
    name: report.driverContractName,
    totalMonthlyBilled: report.totalBilledWithSubscription,
  };
}
