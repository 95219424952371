import { EmspOfferInvitationAcceptance } from '@model/evd/emsp';
import { uniqueId } from 'lodash-es';
import { z } from 'zod';

export const EmspOfferInvitationAcceptanceJsonSchema = z.object({
  companyName: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  street: z.string(),
  streetNumber: z.string().optional(),
  zipCode: z.string().optional(),
  city: z.string(),
  countryCode: z.string(),
  formatted: z.string(),
});

export type EmspOfferInvitationAcceptanceJson = z.infer<
  typeof EmspOfferInvitationAcceptanceJsonSchema
>;

export function serializeEmspOfferInvitationAcceptance(
  invitation: EmspOfferInvitationAcceptance,
): EmspOfferInvitationAcceptanceJson {
  return {
    ...invitation,
  };
}

export function deserializeEmspOfferInvitationAcceptance(
  invitation: EmspOfferInvitationAcceptanceJson,
): EmspOfferInvitationAcceptance {
  return {
    ...invitation,
    id: uniqueId(),
  };
}
