import { IdJsonSchema } from '@api';
import { ConnectorType } from '@model';
import { EmspContractLocationCharger } from '@model/evd/emsp';
import { z } from 'zod';

export const EmspContractLocationChargerJsonSchema = z.object({
  chargerName: z.string().optional(),
  chargerSerialNumber: z.string().optional(),
  chargerId: IdJsonSchema,
  evseId: IdJsonSchema,
  connectorTypes: z.array(z.nativeEnum(ConnectorType)),
  pricePerKwh: z.number().optional(),
  pricePerMinute: z.number().optional(),
  fixedFee: z.number().optional(),
  idleFee: z.number().optional(),
  idleFeeApplicableAfterMinutes: z.number().optional(),
  currencyCode: z.string().optional(),
});

export type EmspContractLocationChargerJson = z.infer<
  typeof EmspContractLocationChargerJsonSchema
>;

export function deserializeEmspContractLocationCharger(
  charger: EmspContractLocationChargerJson,
): EmspContractLocationCharger {
  return {
    ...charger,
    id: charger.chargerId,
    name: charger.chargerName,
    serialNumber: charger.chargerSerialNumber,
  };
}

export function serializeEmspContractLocationCharger(
  charger: EmspContractLocationCharger,
): EmspContractLocationChargerJson {
  return {
    ...charger,
    chargerId: charger.id,
    chargerName: charger.name,
    chargerSerialNumber: charger.serialNumber,
  };
}
