import { IdJsonSchema } from '@api';
import { EmspContractLocation } from '@model/evd/emsp';
import { z } from 'zod';

import {
  EmspContractLocationChargerJsonSchema,
  deserializeEmspContractLocationCharger,
  serializeEmspContractLocationCharger,
} from './emsp-contract-location-charger.io';

export const EmspContractLocationJsonSchema = z.object({
  locationId: IdJsonSchema,
  locationName: z.string(),
  chargers: z.array(EmspContractLocationChargerJsonSchema),
});

export type EmspContractLocationJson = z.infer<
  typeof EmspContractLocationJsonSchema
>;

export function deserializeEmspContractLocation(
  location: EmspContractLocationJson,
): EmspContractLocation {
  return {
    ...location,
    id: location.locationId,
    name: location.locationName,
    chargers: location.chargers.map(deserializeEmspContractLocationCharger),
  };
}

export function serializeEmspContractLocation(
  location: EmspContractLocation,
): EmspContractLocationJson {
  return {
    ...location,
    locationId: location.id,
    locationName: location.name,
    chargers: location.chargers.map(serializeEmspContractLocationCharger),
  };
}
