import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, getMandatorySelfLink, makeLink } from '@model';
import { EmspContractReport, ListEmspContractReport } from '@model/evd/emsp';
import { Observable } from 'rxjs';
import { ZodType, z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  EmspContractReportJson,
  EmspContractReportJsonSchema,
  ListEmspContractReportJson,
  ListEmspContractReportJsonSchema,
  deserializeEmspContractReport,
  deserializeListEmspContractReport,
  serializeEmspContractReport,
} from './io/emsp-contract-report.io';

@Injectable({
  providedIn: 'root',
})
export class EmspContractReportsApiService extends AbstractEvdApiService<
  EmspContractReport,
  EmspContractReportJson,
  ListEmspContractReport,
  ListEmspContractReportJson
> {
  public constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(item: EmspContractReport): EmspContractReportJson {
    return serializeEmspContractReport(item);
  }

  protected deserialize(item: EmspContractReportJson): EmspContractReport {
    return deserializeEmspContractReport(item);
  }

  protected getLinksLink(): Link {
    return makeLink('/driver/.links');
  }

  protected getJsonSchema(): ZodType<EmspContractReportJson> {
    return EmspContractReportJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<ListEmspContractReportJson> {
    return ListEmspContractReportJsonSchema;
  }

  protected listDeserialize(
    item: ListEmspContractReportJson,
  ): ListEmspContractReport {
    return deserializeListEmspContractReport(item);
  }

  public downloadReport(report: ListEmspContractReport): Observable<Blob> {
    const link = getMandatorySelfLink(report);
    return this.httpClient.get(link.href, { responseType: 'blob' });
  }
}
