import {
  Id,
  Link,
  MultiLanguage,
  WithId,
  WithLinks,
  getMandatoryRelation,
  hasRelation,
} from '@model';

import { EmspContractLocation } from './emsp-contract-location';
import { EmspSubscriptionPlan } from './emsp-subscription-plan';
import { EmspContractStatus } from './enums/emsp-contract-status';

export const SELF_CANCEL_RELATION = 'self:delete';
export const COLLECTION_SITES_RELATION = 'collection:sites';
export const CONTRACT_END_DATE_RELATION = 'self:contract-enddate';

export interface EmspContract extends WithId, WithLinks {
  driverContractId: Id;
  organizationName: string;
  contractName: MultiLanguage;
  contractDescription: MultiLanguage;
  startsOn: Date;
  endsOn?: Date;
  currencyCode: string;
  subscriptionPlan?: EmspSubscriptionPlan;
  locations: EmspContractLocation[];
}

export interface ListEmspContract extends WithId, WithLinks {
  name: MultiLanguage;
  organizationName: string;
  startsOn: Date;
  endsOn?: Date;
  pricePerMonth?: number;
  status: EmspContractStatus;
  currencyCode: string;
}

export interface UnlinkedEmspContract extends WithId {
  name: MultiLanguage;
}

export function isEmspContractScheduled(offer: ListEmspContract): boolean {
  return offer.status === EmspContractStatus.Scheduled;
}

export function canCancelContract(
  contract: EmspContract | ListEmspContract,
): boolean {
  return hasRelation(contract, SELF_CANCEL_RELATION);
}

export function getMandatorySitesLink(contract: EmspContract): Link {
  return getMandatoryRelation(contract, COLLECTION_SITES_RELATION);
}

export function getMandatoryCancelContractLink(
  contract: ListEmspContract | EmspContract,
) {
  return getMandatoryRelation(contract, SELF_CANCEL_RELATION);
}

export function getMandatoryContractEndDateLink(
  contract: ListEmspContract | EmspContract,
) {
  return getMandatoryRelation(contract, CONTRACT_END_DATE_RELATION);
}
