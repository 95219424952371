import {
  MultiLanguageJsonSchema,
  deserializeOptionalDate,
  serializeOptionalDate,
} from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { AddressType } from '@model';
import { EmspOfferInvitation } from '@model/evd/emsp';
import { isNil, uniqueId } from 'lodash-es';
import { z } from 'zod';

import { EmspChargingSessionFeeSchema } from './emsp-charging-session-fee.io';

export const EmspOfferInvitationSchema = z.object({
  isValid: z.boolean(),
  emspName: z.string().optional(),
  offerName: MultiLanguageJsonSchema.optional(),
  currencyCode: z.string().optional(),
  chargingSessionFee: EmspChargingSessionFeeSchema.optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  termsOfServiceUrl: z.string().optional(),
  driverBillingAddress: z
    .object({
      street: z.string().optional(),
      streetNumber: z.string().optional(),
      zipCode: z.string().optional(),
      city: z.string().optional(),
      countryCode: z.string().optional(),
      formatted: z.string().optional(),
    })
    .optional(),
  _links: LinksJson(environment.urls.api).optional(),
});

export type EmspOfferInvitationJson = z.infer<typeof EmspOfferInvitationSchema>;

export function serializeEmspOfferInvitation(
  invitation: EmspOfferInvitation,
): EmspOfferInvitationJson {
  return {
    ...invitation,
    startDate: serializeOptionalDate(invitation.startDate),
    endDate: serializeOptionalDate(invitation.endDate),
    driverBillingAddress: isNil(invitation.driverBillingAddress)
      ? undefined
      : {
          ...invitation.driverBillingAddress,
          countryCode: invitation.driverBillingAddress?.country,
          formatted: invitation.driverBillingAddress?.formattedAddress,
        },
  };
}

export function deserializeEmspOfferInvitation(
  invitation: EmspOfferInvitationJson,
): EmspOfferInvitation {
  return {
    ...invitation,
    id: uniqueId(),
    startDate: deserializeOptionalDate(invitation.startDate),
    endDate: deserializeOptionalDate(invitation.endDate),
    driverBillingAddress: isNil(invitation.driverBillingAddress)
      ? undefined
      : {
          ...invitation.driverBillingAddress,
          id: uniqueId(),
          type: AddressType.Billing,
          country: invitation.driverBillingAddress?.countryCode,
          formattedAddress: invitation.driverBillingAddress?.formatted,
        },
  };
}
