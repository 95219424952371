import { MultiLanguageJsonSchema } from '@api';
import { z } from 'zod';

export const EmspSubscriptionPlanSchema = z.object({
  subscriptionPlanDescription: MultiLanguageJsonSchema.optional(),
  pricePerMonth: z.number(),
});

export type EmspSubscriptionPlanJson = z.infer<
  typeof EmspSubscriptionPlanSchema
>;
