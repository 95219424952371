import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, LinkMethod } from '@model';
import { EmspOfferInvitation } from '@model/evd/emsp';
import { ZodType, z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  EmspOfferInvitationJson,
  EmspOfferInvitationSchema,
  deserializeEmspOfferInvitation,
  serializeEmspOfferInvitation,
} from './io/emsp-offer-invitation.io';

@Injectable()
export class EmspOfferInvitationApiService extends AbstractEvdApiService<
  EmspOfferInvitation,
  EmspOfferInvitationJson
> {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(item: EmspOfferInvitation): EmspOfferInvitationJson {
    return serializeEmspOfferInvitation(item);
  }

  protected deserialize(item: EmspOfferInvitationJson): EmspOfferInvitation {
    return deserializeEmspOfferInvitation(item);
  }

  protected getLinksLink(): Link {
    return {
      href: '/emsp-offers/invitations/.links',
      method: LinkMethod.GET,
      templated: false,
    };
  }

  protected getJsonSchema(): ZodType<EmspOfferInvitationJson> {
    return EmspOfferInvitationSchema;
  }

  protected getListJsonSchema(): z.Schema<EmspOfferInvitationJson> {
    return EmspOfferInvitationSchema;
  }

  protected listDeserialize(
    item: EmspOfferInvitationJson,
  ): EmspOfferInvitation {
    return deserializeEmspOfferInvitation(item);
  }
}
