import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializeOptionalDate } from '@api';
import {
  ListParameters,
  PaginatedResults,
  getHref,
  getPaginatedResults,
  makeListParameters,
} from '@api/hateos';
import { Link, LinkMethod } from '@model';
import {
  EmspContract,
  ListEmspContract,
  UnlinkedEmspContract,
} from '@model/evd/emsp';
import { isNil } from 'lodash-es';
import { Observable, catchError, map } from 'rxjs';
import { ZodType, z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  EmspContractJson,
  EmspContractJsonSchema,
  ListEmspContractJson,
  ListEmspContractJsonSchema,
  UnlinkedEmspContractJson,
  UnlinkedEmspContractJsonSchema,
  deserializeEmspContract,
  deserializeListEmspContract,
  deserializeUnlinkedEmspContract,
  serializeEmspContract,
} from './io/emsp-contract.io';

@Injectable()
export class EmspContractsApiService extends AbstractEvdApiService<
  EmspContract,
  EmspContractJson,
  ListEmspContract,
  ListEmspContractJson
> {
  public constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getUnlinkedContracts(
    link: Link,
    listParameters?: ListParameters<UnlinkedEmspContract>,
  ): Observable<PaginatedResults<UnlinkedEmspContract> | Error> {
    this.checkLinkMethod(link, LinkMethod.GET);

    const parameters = makeListParameters(link, listParameters);

    return this.httpClient
      .get<unknown>(getHref(link, parameters), {
        observe: 'response',
      })
      .pipe(
        map(({ body }) => {
          return getPaginatedResults<
            UnlinkedEmspContractJson,
            UnlinkedEmspContract
          >(
            body,
            UnlinkedEmspContractJsonSchema,
            this.getApiUrl(),
            deserializeUnlinkedEmspContract,
          );
        }),
        catchError((error: HttpErrorResponse) => {
          return this.catchError(error);
        }),
      );
  }

  public getEmspContractEndDate(link: Link): Observable<Date | Error> {
    this.checkLinkMethod(link, LinkMethod.GET);
    return this.httpClient
      .get<{ endDate: string }>(getHref(link), {
        observe: 'response',
      })
      .pipe(
        map(({ body }) => {
          const date = deserializeOptionalDate(body?.endDate);
          return isNil(date) ? new Error() : date;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.catchError(error);
        }),
      );
  }

  protected serialize(item: EmspContract): EmspContractJson {
    return serializeEmspContract(item);
  }

  protected deserialize(item: EmspContractJson): EmspContract {
    return deserializeEmspContract(item);
  }

  protected getLinksLink(): Link {
    return {
      href: '/driver/.links',
      method: LinkMethod.GET,
      templated: false,
    };
  }

  protected getJsonSchema(): ZodType<EmspContractJson> {
    return EmspContractJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<ListEmspContractJson> {
    return ListEmspContractJsonSchema;
  }

  protected listDeserialize(item: ListEmspContractJson): ListEmspContract {
    return deserializeListEmspContract(item);
  }
}
