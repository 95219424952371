import { ConnectorType, Id, WithId } from '@model';

export interface EmspContractLocationCharger extends WithId {
  evseId: Id;
  name?: string;
  serialNumber?: string;
  connectorTypes: ConnectorType[];
  pricePerKwh?: number;
  pricePerMinute?: number;
  fixedFee?: number;
  idleFee?: number;
  idleFeeApplicableAfterMinutes?: number;
}

export function getDisplayName(evCharger: EmspContractLocationCharger): string {
  return `${evCharger.name ?? evCharger.serialNumber ?? evCharger.id.toString()} ${evCharger.evseId}`;
}
